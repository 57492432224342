import acucarMascavo from '../../imagens/mascavo.svg';
import Pistache from '../../imagens/pistache.svg';
import ChocolateBranco from '../../imagens/chocolateBranco.svg';


export const sabores = [
   
    {nome: 'Licor Fino de Açucar Mascavo', id: 7, src: acucarMascavo},
    {nome: 'Licor Fino de Pistache', id: 7, src: Pistache},
    {nome: 'Licor cremoso de Chocolate Branco', id: 7, src: ChocolateBranco},
    
]  
    