import React from "react";

import CardCatalogo from "../componentes/CarCatalogo/CardCatalogo.js";




function Catalogo() {
  return (

    <>
      
        <CardCatalogo/>
        
    
    </>
       
        
      
    
  );
}

export default Catalogo;
